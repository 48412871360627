<template>
  <YscCard no-body-padding-y>
    <div class="divide-y">
      <div class="flex justify-between space-x-4 py-4">
        <span>{{ $t('commons.booking_number') }}</span>
        <span class="text-right font-semibold">#<slot name="booking-id" /></span>
      </div>

      <div
        v-if="$slots['vehicle-registration']"
        class="flex justify-between space-x-4 py-4"
      >
        <span>{{ $t('commons.vehicle') }}</span>
        <span class="font-semibold"><slot name="vehicle-registration" /></span>
      </div>

      <div class="flex items-center justify-between space-x-4 py-4">
        <div class="grow basis-0">
          <p class="font-semibold">
            <slot name="date-from" />
          </p>
          <p class="text-sm">
            <slot name="hour-from" />
          </p>
        </div>
        <YscIconsArrowRight class="h-6 w-6" />
        <div class="grow basis-0">
          <p class="text-right font-semibold">
            <slot name="date-to" />
          </p>
          <p class="text-right text-sm">
            <slot name="hour-to" />
          </p>
        </div>
      </div>

      <div class="flex justify-between space-x-4 py-4">
        <span>{{ $t('data.booking.travelers.label') }}</span>
        <span class="text-right font-semibold"><slot name="travelers" /></span>
      </div>

      <div
        v-if="$slots.remuneration"
        class="flex justify-between space-x-4 py-4"
      >
        <span>{{ $t('commons.remuneration') }}</span>
        <span class="text-right font-semibold"><slot name="remuneration" /></span>
      </div>

      <div
        v-if="$slots.price"
        class="flex justify-between space-x-4 py-4"
      >
        <span>{{ $t('components.app_booking_summary.price') }}</span>
        <span class="text-right font-semibold"><slot name="price" /></span>
      </div>

      <div class="flex justify-between space-x-4 py-4">
        <span>{{ $t('components.app_booking_summary.insurance') }}</span>
        <span class="text-right font-semibold"><slot name="insurance" /></span>
      </div>

      <div class="flex justify-between space-x-4 py-4">
        <span>{{ $t('data.product.deposit.label') }}</span>
        <div>
          <p class="text-right font-semibold">
            <slot name="deposit">
              {{ $t('components.app_booking_summary.yescapa_deposit') }} <sup>*</sup>
            </slot>
          </p>
          <p class="text-right text-sm">
            <slot name="deposit-means" />
          </p>
        </div>
      </div>

      <div class="flex justify-between space-x-4 py-4">
        <span>{{ $t('data.booking.km_option.label') }}</span>
        <div>
          <p class="text-right font-semibold">
            <slot name="km-option" />
          </p>
          <p
            v-if="$slots['km-option-details']"
            class="text-right text-sm"
          >
            <slot name="km-option-details" />
          </p>
        </div>
      </div>

      <div class="flex justify-between space-x-4 py-4">
        <span>{{ $t('components.app_booking_summary.countries.label') }}</span>
        <span class="text-right font-semibold"><slot name="travelled-countries" /></span>
      </div>
    </div>

    <template
      v-if="!$slots.deposit"
      #extraFooterBlock
    >
      <p class="text-right italic text-gray-500">
        <sup>*</sup> {{ $t('components.app_booking_summary.yescapa_deposit_details') }}
      </p>
    </template>
  </YscCard>
</template>
