<script setup lang="ts">
import type { SubmitAction } from '~/types/commons'

export interface AppChatFormProps {
  id?: string
  text?: string | null
  showInstantBookingWarning: boolean
  canSendMessage: boolean
  submitAction: SubmitAction<{ text: string }>
}

const props = withDefaults(defineProps<AppChatFormProps>(), {
  id: 'text-chat',
  text: null,
})

const emits = defineEmits<{
  'update:text': [string | null]
}>()

const form = ref({ text: props.text })

watch(() => form.value.text, (val) => {
  if (val !== props.text) {
    emits('update:text', val)
  }
})

watch(() => props.text, (val) => {
  if (val !== form.value.text) {
    form.value.text = val
  }
})

const { sendingForm, setSendingForm } = useSendingForm()
provide(setSendingFormKey, { setSendingForm })

const onSubmit = async () => {
  if (!form.value.text) {
    return
  }
  await props.submitAction({ form: { text: form.value.text } })
  form.value.text = null
}
</script>

<template>
  <div>
    <div
      class="py-2 rounded mb-4 flex justify-center items-center px-4"
      :class="showInstantBookingWarning ? 'bg-peacock-100' : 'bg-orange-100'"
    >
      <template v-if="showInstantBookingWarning">
        <YscIconsInfo class="text-peacock-600 inline-block w-6 min-w-6 mr-2" />
        <span>{{ $t('components.app_chat.conversation_unavailable_instant_booking_warning') }}</span>
      </template>
      <template v-else>
        <YscIconsWarningTriangle class="text-orange-600 inline-block w-6 min-w-6 mr-2" />
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span v-html="$t('components.app_chat.fraud_warning_html')" />
      </template>
    </div>

    <YscForm
      v-if="canSendMessage"
      :id="`${id}-form`"
      :submit-action="onSubmit"
    >
      <YscInputTextarea
        :id="id"
        v-model="form.text"
        :disabled="sendingForm"
        :placeholder="$t('data.chat.text.placeholder')"
        :rows="3"
        class="w-full"
        required
      />
      <p class="text-gray-500 text-sm">
        {{ $t('components.app_chat.hidden_text_explanation') }}
      </p>

      <div class="flex items-center justify-end">
        <YscButtonSubmit :loading="sendingForm">
          {{ $t('commons.actions.submit') }}
        </YscButtonSubmit>
      </div>
    </YscForm>
  </div>
</template>
