import { Constants } from '@yescapa-dev/ysc-api-js/legacy'
import type { BookingOwnerDetails, BookingsGuestDetails, Routing } from '@yescapa-dev/ysc-api-js/legacy'
import type { TravelDocument } from '~/types/documents'

export const useTravelDocuments = ({ booking, moreLinks }: { booking: BookingOwnerDetails | BookingsGuestDetails, moreLinks: { contractTerms?: Routing['contract'], insuranceTerms?: Routing['list_insurances'], cancellationInsuranceTerms?: string } }): TravelDocument[] => {
  if (!booking) {
    return []
  }

  const assets = getDynamicAssetsMap(import.meta.glob('~/assets/img/booking/*.svg', { eager: true }))

  return [
    {
      key: 'renting_contract',
      isAvailable: !!booking.physical_contract_url,
      title: 'components.app_booking_details_travel_documents.renting_contract.title',
      description: 'components.app_booking_details_travel_documents.renting_contract.description_use_case',
      link: booking.physical_contract_url,
      iconSrc: assets.contract as string,
      more: {
        path: 'components.app_booking_details_travel_documents.renting_contract.more_dynamic',
        link: moreLinks.contractTerms,
        linkText: 'components.app_booking_details_travel_documents.renting_contract.link_more',
      },
    },
    {
      key: 'insurance_certificate',
      isAvailable: booking.insurance.slug !== Constants.INSURANCES.OWNER_MANAGED_INSURANCE_SLUG && booking.insurance.certificate_url !== null,
      title: 'components.app_booking_details_travel_documents.insurance_certificate.title',
      description: 'components.app_booking_details_travel_documents.insurance_certificate.description',
      link: booking.insurance.certificate_url,
      iconSrc: assets.certificate as string,
      more: {
        path: 'components.app_booking_details_travel_documents.insurance_certificate.more_dynamic',
        link: moreLinks.insuranceTerms,
        linkText: 'components.app_booking_details_travel_documents.insurance_certificate.link_more',
      },
    },
    {
      key: 'cancel_insurance_certificate',
      isAvailable: 'cancel_insurance' in booking && !!booking.cancel_insurance?.contract_url,
      title: 'components.app_booking_details_travel_documents.cancel_insurance_certificate.title',
      description: 'components.app_booking_details_travel_documents.cancel_insurance_certificate.description',
      link: 'cancel_insurance' in booking ? booking.cancel_insurance?.contract_url : null,
      iconSrc: assets.certificate as string,
      more: {
        path: 'components.app_booking_details_travel_documents.cancel_insurance_certificate.more_dynamic',
        link: moreLinks.cancellationInsuranceTerms,
        linkText: 'components.app_booking_details_travel_documents.cancel_insurance_certificate.link_more',
      },
    },
  ].filter(({ isAvailable }) => isAvailable)
}
