<script setup lang="ts">
import { Constants } from '@yescapa-dev/ysc-api-js/legacy'
import type { RouteLocationRaw } from '#vue-router'
import type { BookingsPrices } from '~/types/bookingRequest'

interface Props {
  prices: BookingsPrices
  userType: 'guest' | 'owner'
  camperImagePath?: string
  camperTitle?: string | null
  camperCity: string
  camperPassengers: number
  camperPath?: RouteLocationRaw | null
  totalKm?: number
  kmOption?: number | null
  isSelfInsured?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  camperPath: null,
  camperImagePath: undefined,
  totalKm: 0,
  kmOption: null,
  isSelfInsured: false,
  camperTitle: null,
})

const isGuest = computed(() => props.userType === Constants.USERS.TYPES.GUEST)
</script>

<template>
  <YscCard>
    <template #title>
      <template v-if="isGuest">
        {{ $t('commons.booking_owner_details') }}
      </template>
      <template v-else>
        {{ $t('components.app_price_details.title.owner') }}
      </template>
    </template>

    <div class="sm:flex sm:flex-row-reverse sm:space-x-6 sm:space-x-reverse">
      <div
        v-if="camperPath"
        class="relative mb-4 sm:mb-0 sm:w-1/2"
      >
        <TwicImg
          :src="camperImagePath ? twicpicsPath(camperImagePath) : undefined"
          :alt="camperTitle"
          ratio="16/9"
          placeholder="maincolor"
          class="aspect-video rounded"
        />
        <div class="px-1.5 py-3">
          <div class="flex items-center justify-start">
            <NuxtLink
              :to="camperPath"
              class="overflow-hidden whitespace-nowrap"
            >
              <h2 class="truncate text-xl font-semibold">
                {{ camperTitle }}
              </h2>
              <span
                class="absolute inset-0"
                aria-hidden="true"
              />
            </NuxtLink>
          </div>
          <p class="text-sm text-gray-500">
            {{ camperCity }}
          </p>
          <p class="text-sm text-gray-500">
            {{ $t('components.app_product_card.vehicles.passengers_plural', { count_number: $n(camperPassengers) }, camperPassengers) }}
          </p>
        </div>
      </div>

      <div :class="camperPath ? 'sm:w-1/2' : 'w-full'">
        <AppPriceDetails
          :total-km="totalKm"
          :km-option="kmOption"
          :prices="prices"
          :user-type="userType"
          :is-self-insured="isSelfInsured"
        />
        <slot />
      </div>
    </div>
  </YscCard>
</template>
