<script setup lang="ts">
import type { DepositMean } from '@yescapa-dev/ysc-api-js/legacy'

interface Props {
  isOwner?: boolean
  depositMeans?: DepositMean[]
  isYescapaDepositGuaranty?: boolean
  hasInsurance?: boolean
  visitedCountries?: string[]
  documentPreventionUrl?: string | null
}

const props = withDefaults(defineProps<Props>(), {
  isOwner: false,
  depositMeans: () => [],
  isYescapaDepositGuaranty: false,
  hasInsurance: false,
  visitedCountries: () => [],
  documentPreventionUrl: null,
})

const { t } = useI18n()
const referentialStore = useReferentialStore()
const { getReferentialMaps } = useReferentialStore()
const { referential } = storeToRefs(referentialStore)
const { routing } = storeToRefs(useRoutingStore())
const { localeProperties: { yscCode } } = useLocaleProperties()

const isFranceVisited = computed(() => props.visitedCountries.includes('FR'))

const isPortugalVisited = computed(() => props.visitedCountries.includes('PT'))

const bookingTipsEssentials = computed(() => {
  let tips
  if (props.isOwner) {
    tips = [
      {
        text: t('components.app_booking_tips.essentials.verify_vehicle'),
        show: true,
      },
      {
        text: t('components.app_booking_tips.essentials.contact_guest'),
        show: true,
      },
      {
        text: t('components.app_booking_tips.essentials.remind_deposit'),
        show: !props.isYescapaDepositGuaranty,
      },
      {
        text: t('components.app_booking_tips.essentials.print_contract'),
        show: true,
      },
      {
        text: t('components.app_booking_tips.essentials.verify_vehicle_paper'),
        show: true,
      },
      {
        text: t('components.app_booking_tips.essentials.read_guide_owner'),
        show: true,
      },
      {
        text: t('components.app_booking_tips.essentials.plan_for_future'),
        show: true,
      },
    ]
  }
  else {
    tips = [
      {
        text: t('components.app_booking_tips.essentials.contact_owner'),
        show: true,
      },
      {
        text: t('components.app_booking_tips.essentials.prepare_deposit', {
          depositMean: props.depositMeans.map(x => getReferentialMaps('deposit_mean')[x]).join(', '),
        }),
        show: !props.isYescapaDepositGuaranty && props.depositMeans,
      },
      {
        text: t('components.app_booking_tips.essentials.print_contract'),
        show: true,
      },
      {
        text: t('components.app_booking_tips.essentials.print_insurance_certificate'),
        show: props.hasInsurance,
      },
      {
        text: 'components.app_booking_tips.essentials.print_safety_note_dynamic',
        url: props.documentPreventionUrl,
        link: t('components.app_booking_tips.essentials.print_safety_note_link'),
        show: true,
      },
      {
        isDivider: true,
        show: true,
      },
      {
        text: t('components.app_booking_tips.essentials.read_guide_guest'),
        show: true,
      },
      {
        text: 'components.app_booking_tips.essentials.home_camper_dynamic',
        url: 'https://homecamper.com',
        link: 'HomeCamper',
        show: true,
      },
      {
        text: 'components.app_booking_tips.essentials.france_passion_dynamic',
        url: 'https://www.france-passion.com/#utm_source=Yescapa&utm_medium=site&utm_campaign=19_07',
        link: 'France-Passion',
        show: isFranceVisited.value,
      },
      {
        text: 'components.app_booking_tips.essentials.easy_camp_dynamic',
        url: easyCampUrl.value,
        link: 'Portugal Easy Camp',
        show: isPortugalVisited.value,
      },
      {
        text: t('components.app_booking_tips.essentials.enjoy'),
        show: true,
      },
    ]
  }

  return tips.filter(({ show }) => show)
})

const bookingTips = computed(() => {
  if (props.isOwner) {
    return [
      {
        title: t('components.app_booking_tips.guide_owner.title'),
        description: t('components.app_booking_tips.guide_owner.description'),
        url: referential.value?.guide_owner,
      },
    ]
  }

  return [
    {
      title: t('components.app_booking_tips.guide_guest.title'),
      description: t('components.app_booking_tips.guide_guest.description'),
      url: referential.value?.guide_guest,
    },
    {
      title: t('components.app_booking_tips.video.title'),
      description: t('components.app_booking_tips.video.description'),
      url: routing.value?.prevention_video_url,
    },
    {
      title: t('components.app_booking_tips.app.title'),
      description: t('components.app_booking_tips.app.description'),
      url: { name: 'get-apps' },
    },
  ].filter(({ url }) => url)
})

const easyCampUrl = computed(() => {
  switch (getTopLevelLocale(yscCode)) {
    case 'fr':
      return 'https://portugaleasycamp.com/fr/tour/'
    case 'pt':
      return 'https://portugaleasycamp.com/tour/'
    default:
      return 'https://portugaleasycamp.com/en/tour/'
  }
})
</script>

<template>
  <!-- eslint-disable vue/no-v-html -->
  <YscCard>
    <template #title>
      {{ $t('components.app_booking_tips.title') }}
    </template>

    <div class="flex flex-col-reverse gap-8 xl:flex-row">
      <div class="shrink-0 space-y-2">
        <div
          v-for="tip in bookingTipsEssentials"
          :key="`booking-tip-essential-${tip.text}`"
          class="flex items-center space-x-3"
        >
          <hr
            v-if="tip.isDivider"
            class="my-8 w-full"
          >
          <template v-else>
            <YscIconsCheckBold class="h-3 w-3 text-pink-500" />
            <i18n-t
              v-if="tip.url"
              :keypath="tip.text"
              tag="p"
              scope="global"
            >
              <template #link_string>
                <NuxtLink
                  :to="tip.url"
                  class="link link-primary"
                  target="_blank"
                >
                  {{ tip.link }}
                </NuxtLink>
              </template>
              {{ tip.text }}
            </i18n-t>
            <p
              v-else
              class="prose leading-tight"
            >
              {{ tip.text }}
            </p>
          </template>
        </div>
      </div>

      <div class="space-y-3">
        <div
          v-for="tip in bookingTips"
          :key="`booking-tip-${tip.title}`"
        >
          <div class="relative flex items-start space-x-4 rounded hover:bg-gray-100">
            <div class="flex items-center justify-center self-stretch rounded-l bg-gray-100 px-6">
              <YscIconsBulb
                class="h-8 w-8 text-black"
                highlight="text-peacock-200"
              />
            </div>

            <div class="space-y-1 px-2 py-4">
              <h3 class="flex font-semibold">
                <NuxtLink
                  :to="tip.url"
                  target="_blank"
                >
                  {{ tip.title }}
                  <span
                    class="absolute inset-0"
                    aria-hidden="true"
                  />
                </NuxtLink>
              </h3>
              <p class="text-sm">
                {{ tip.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </YscCard>
</template>
