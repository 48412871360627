<script setup lang="ts">
interface Props {
  messageText?: string
  messageIsSystem?: boolean
  messageUserIsAuthor?: boolean
  messageShowAvatar?: boolean
  messageIsRead?: boolean
  messageSendOn?: string
  authorPictureUrl?: string | null
  authorFirstName?: string
  authorLastName?: string
}

const props = withDefaults(defineProps<Props>(), {
  messageText: undefined,
  messageIsSystem: false,
  messageUserIsAuthor: false,
  messageShowAvatar: false,
  messageIsRead: false,
  messageSendOn: undefined,
  authorPictureUrl: null,
  authorFirstName: undefined,
  authorLastName: undefined,
})

const { d } = useI18n()

const formattedDateTime = computed(() => props.messageSendOn ? d(new Date(props.messageSendOn), 'h.numeric m.numeric') : '')
</script>

<template>
  <div>
    <p
      v-if="messageIsSystem"
      class="text-center font-semibold leading-tight text-gray-500"
    >
      {{ messageText }}
    </p>

    <div
      v-else
      :class="{ 'flex justify-end': messageUserIsAuthor }"
    >
      <div class="w-3/5">
        <div
          v-if="!messageUserIsAuthor && messageShowAvatar"
          class="flex h-10 items-center space-x-2"
        >
          <YscAvatar
            :first-name="authorFirstName"
            :picture="authorPictureUrl"
            radius="20"
          />
          <span>{{ authorFirstName }} {{ authorLastName }}</span>
        </div>

        <span
          :class="[messageUserIsAuthor ? 'bg-gray-100' : 'bg-peacock-200']"
          class="mt-2 block whitespace-pre-line rounded px-4 py-2"
        >{{ messageText }}</span>

        <div
          class="mt-1 flex items-center space-x-1 leading-none"
          :class="[messageUserIsAuthor ? 'justify-end' : 'justify-start']"
        >
          <YscIconsCheckBold
            v-if="messageIsRead"
            class="h-3 w-3 stroke-2 text-blue-500"
          />
          <p class="text-xs uppercase text-gray-500">
            {{ formattedDateTime }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
