<script setup lang="ts">
import type { ChatMessageResponse } from '@yescapa-dev/ysc-api-js/legacy'
import { isSameDay } from 'date-fns'
import type { AppChatFormProps } from '../App/Chats/AppChatForm.vue'
import { YSC_API_CHAT_ERROR } from '~/utils/error/YscErrorClasses'

interface Props {
  idChat: number
  idUser: number
  showModal?: boolean
  canSendMessages?: boolean
  showInstantBookingWarning?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  showModal: false,
  canSendMessages: false,
  showInstantBookingWarning: false,
})

const show = ref(false)
const textToSend = ref(null)

type LightChatMessage = {
  id: number
  text: string
  is_read: boolean
  send_on_tz: string
  author_picture_url: string | null
  author_first_name: string | undefined
  author_last_name: string | undefined
  isSystem: boolean
  userIsAuthor: boolean
  showAvatar: boolean
}

export type LightChatMessageGroup = {
  sendOn: string
  messages: LightChatMessage[]
}

const lightMessage = (message: ChatMessageResponse) => ({
  id: message.id,
  text: message.text,
  is_read: message.is_read,
  send_on_tz: message.send_on_tz,
  author_picture_url: message.author_picture_url,
  author_first_name: message.author_first_name,
  author_last_name: message.author_last_name,
  isSystem: !message.author_pk,
  userIsAuthor: message.author_pk === props.idUser,
  showAvatar: false,
})

const { data, pending } = await useAsyncData('ho-chat', async () => {
  const { $api } = useYscApi()
  const { $errorManager } = useErrorManager()
  let chat
  try {
    chat = await $api.chats.getChat(props.idChat)
  }
  catch (e) {
    if (e instanceof Error) {
      $errorManager({ e, name: YSC_API_CHAT_ERROR })
    }
    return []
  }
  const [lastMessage] = chat.messages.slice(0, 1)
  // API will update is_read status for the received message and
  // the older ones too if the connected user is the receiver
  if (lastMessage && !lastMessage.is_read) {
    try {
      await $api.chats.markAsRead(lastMessage.id)
      chat = await $api.chats.getChat(props.idChat)
    }
    catch (e) {
      if (e instanceof Error) {
        $errorManager({ e, name: YSC_API_CHAT_ERROR })
      }
      return []
    }
  }

  const messageGroups = chat.messages.map<LightChatMessage>((m, i, arr) => {
    const message = lightMessage(m)
    message.showAvatar = !message.isSystem && !message.userIsAuthor && m.author_pk !== arr[i + 1]?.author_pk
    return message
  }).reduce<LightChatMessageGroup[]>((acc, cur) => {
    const last = acc[acc.length - 1]
    if (last && isSameDay(new Date(last.sendOn), new Date(cur.send_on_tz))) {
      acc[acc.length - 1].messages = [cur, ...last.messages]
    }
    else {
      acc.push({
        sendOn: cur.send_on_tz,
        messages: [cur],
      })
    }
    return acc
  }, [])

  return messageGroups
})

const emits = defineEmits<{
  close: []
}>()

watch(() => props.showModal, (val) => {
  show.value = val
}, { immediate: true })

watch(show, (val) => {
  if (!val) {
    emits('close')
  }
})

const { $api } = useYscApi()
const { $errorManager } = useErrorManager()

const onSubmit: AppChatFormProps['submitAction'] = async ({ form } = {}) => {
  let lmessage: LightChatMessage
  if (!form) return
  try {
    const message = await $api.chats.postChatMessage(props.idChat, toValue(form))
    lmessage = lightMessage(message)
  }
  catch (e) {
    if (e instanceof Error) {
      $errorManager({ e, name: YSC_API_CHAT_ERROR })
    }
    return
  }
  if (!data.value) {
    return
  }
  const index = data.value.findIndex(g => isSameDay(new Date(g.sendOn), new Date(lmessage.send_on_tz)))
  if (index === -1) {
    data.value.unshift({
      sendOn: lmessage.send_on_tz,
      messages: [lmessage],
    })
  }
  else {
    data.value[index].messages.push(lmessage)
  }
}
</script>

<template>
  <div>
    <YscCard no-body-padding>
      <template #title>
        {{ $t('components.app_chat.title') }}
      </template>

      <template #action>
        <button
          type="button"
          class="btn btn-secondary text-sm"
          @click="show = true"
        >
          {{ $t('commons.actions.extend') }}
        </button>
      </template>

      <AppLoading v-if="pending" />
      <div v-else-if="data">
        <AppChatMessagesDisplay
          class="max-h-[300px]"
          :message-groups="data"
        />
      </div>

      <template
        #footerBlock
      >
        <AppChatForm
          id="chat"
          v-model:text="textToSend"
          :can-send-message="canSendMessages"
          :show-instant-booking-warning="showInstantBookingWarning"
          :submit-action="onSubmit"
        />
      </template>
    </YscCard>

    <YscModal
      v-if="show"
      @close="show = false"
    >
      <template #title>
        {{ $t('components.app_chat.title') }}
      </template>

      <AppLoading v-if="pending" />
      <div v-else-if="data">
        <AppChatMessagesDisplay
          :message-groups="data"
        />
      </div>

      <template
        #footer
      >
        <AppChatForm
          id="modal-chat"
          v-model:text="textToSend"
          :can-send-message="canSendMessages"
          :show-instant-booking-warning="showInstantBookingWarning"
          :submit-action="onSubmit"
        />
      </template>
    </YscModal>
  </div>
</template>
