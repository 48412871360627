import { BOOKING_OWNER_CANCEL_REASONS } from '@yescapa-dev/ysc-api-js/modern'
import type { EnumToPrimitiveUnion } from '@yescapa-dev/ysc-api-js/modern'

const { REASON_BREAKDOWN, REASON_MOT, REASON_UNAVAILABLE, REASON_SALE, REASON_PERSONAL, REASON_PRICES, REASON_OTHER } = BOOKING_OWNER_CANCEL_REASONS

type ReasonCancellationInformation = {
  label: string
  warning: string
  isCalendarUnavailable: boolean
  isProofRequired: boolean
}

export const useBookingOwnerCancel = () => {
  const { $i18n: { t } } = useNuxtApp()
  const reasonsMap: Record<EnumToPrimitiveUnion<BOOKING_OWNER_CANCEL_REASONS>, ReasonCancellationInformation> = {
    [REASON_BREAKDOWN]: {
      label: t('data.raison_cancel_owner.breakdown'),
      warning: t('components.app_modal_booking_owner_cancel.warning.repeated_cancel'),
      isCalendarUnavailable: true,
      isProofRequired: true,
    },
    [REASON_MOT]: {
      label: t('data.raison_cancel_owner.mot'),
      warning: t('components.app_modal_booking_owner_cancel.warning.mot'),
      isCalendarUnavailable: true,
      isProofRequired: true,
    },
    [REASON_UNAVAILABLE]: {
      label: t('data.raison_cancel_owner.unavailable'),
      warning: t('components.app_modal_booking_owner_cancel.warning.repeated_cancel'),
      isCalendarUnavailable: false,
      isProofRequired: false,
    },
    [REASON_SALE]: {
      label: t('data.raison_cancel_owner.sale'),
      warning: t('components.app_modal_booking_owner_cancel.warning.sale'),
      isCalendarUnavailable: false,
      isProofRequired: false,
    },
    [REASON_PERSONAL]: {
      label: t('data.raison_cancel_owner.personal'),
      warning: t('components.app_modal_booking_owner_cancel.warning.no_reason'),
      isCalendarUnavailable: false,
      isProofRequired: false,
    },
    [REASON_PRICES]: {
      label: t('data.raison_cancel_owner.prices'),
      warning: t('components.app_modal_booking_owner_cancel.warning.prices'),
      isCalendarUnavailable: false,
      isProofRequired: false,
    },
    [REASON_OTHER]: {
      label: t('data.raison_cancel_owner.other'),
      warning: t('components.app_modal_booking_owner_cancel.warning.no_reason'),
      isCalendarUnavailable: false,
      isProofRequired: false,
    },
  }
  return {
    getBookingOwnerCancelReasons: () => {
      return Object.keys(reasonsMap).map(key => ({
        value: key,
        text: reasonsMap[key].label,
      }))
    },

    getBookingOwnerCancelInformation: (reason: EnumToPrimitiveUnion<BOOKING_OWNER_CANCEL_REASONS> | null) => {
      if (!reason) {
        return null
      }
      return reasonsMap[reason] || null
    },
  }
}
